module.exports = {
  site: { title: 'DA-CG', email: 'Admin@da-cg.com', background: 'grey' },
  // home: { path: 'home', title: 'home' },
  navigation: [
    { path: '/', title: 'HOME', },
    { path: '/about', title: 'ABOUT', },
    { path: '/contact', title: 'CONTACT',}
  ],
  gallery: [
    { path: '/architecture', title: 'architecture' },
    { path: '/interior', title: 'interior' },
    { path: '/animation', title: 'animation' },
    { path: '/vr', title: 'VR' }
  ],

  thumb: { width: 87, height: 56, background: 'rgba(27, 27, 27, 0.75)' },
  slider: { speed: 500, autoplaySpeed: 5000, background: 'black' },
  bar: { height: 40 },
  logo: { height: 56 },
  arrow: { height: 40 },
  post: {
    fontSize: '1em',
    color: 'white' 
  },
}